import $ from 'jquery';
var Flickity = require('flickity');
require('flickity-as-nav-for');
require('flickity-imagesloaded');

// banner carousel
if ($('.js-carousel').length) {
  var flkty = new Flickity('.js-carousel', {
    // options...
    groupCells: true,
    prevNextButtons: false,
    setGallerySize: true,
    autoPlay: 3500,
    imagesLoaded: true,
    wrapAround: true,
    cellAlign: 'left'
  });
}

// image carousel
if ($('.js-image-carousel').length) {
  var flkty = new Flickity('.js-image-carousel', {
    // options...
    groupCells: true,
    adaptiveHeight: true,
    prevNextButtons: true,
    setGallerySize: true,
    autoPlay: false,
    pageDots: false,
    hash: true,
    imagesLoaded: true,
    cellAlign: 'left'
  });
}

// nav for image carousel
if ($('.js-image-carousel-nav').length) {
  var flkty = new Flickity('.js-image-carousel-nav', {
    // options...
    asNavFor: '.js-image-carousel',
    contain: true,
    imagesLoaded: true,
    prevNextButtons: false,
    pageDots: false
  });
}
