import $ from 'jquery';

$(document).ready(function () {
	function buttonUp() {
		var valux = $('.search-bar__input').val();
		valux = $.trim(valux).length;
		if (valux !== 0) {
			$('.search-bar__button').css('z-index', '99');
		} else {
			$('.search-bar__input').val('');
			$('.search-bar__button').css('z-index', '-999');
		}
	}

	$('.search-bar').on('keyup', '.search-bar__input', function () {
		buttonUp();
	});
});

$(document).ready(function () {
	var submitIcon = $('.search-bar__icon');
	var submitInput = $('.search-bar__input');
	var searchBox = $('.search-bar');
	var isOpen = false;

	$(document).mouseup(function () {
		if (isOpen == true) {
			submitInput.val('');
			$('.search-bar__button').css('z-index', '-999');
			submitIcon.click();
		}
	});

	submitIcon.mouseup(function () {
		return false;
	});

	searchBox.mouseup(function () {
		return false;
	});

	submitIcon.click(function () {
		if (isOpen == false) {
			searchBox.addClass('search-bar--active');
			isOpen = true;
		} else {
			searchBox.removeClass('search-bar--active');
			isOpen = false;
		}
	});
});