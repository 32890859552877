import $ from "jquery";
import "./carousel.js";
import "./headeroom.js";
import "./burger-menu";
import "./language-menu";
import "./expand-search";
import "./filter-dropdown";
import "./same-height";
import "./product-foldout";
import "./nonce";
import "./loadmore";
import "./tab";
import "./accordian";
import "./cookie";

// Remove transitions while page is loading and being resized
var resizeTimer;

$(document).ready(function () {
  setTimeout(function () {
    $("body").removeClass("no-transitions");
  }, 300);
});

$(window).on("resize", function (e) {
  clearTimeout(resizeTimer);
  $("body").addClass("no-transitions");

  resizeTimer = setTimeout(function () {
    $("body").removeClass("no-transitions");
  }, 250);
});

// Prevent Right Click on Images
const images = [...document.querySelectorAll("img")];
if (images) {
  images.map((image) => {
    image.oncontextmenu = function (e) {
      e.preventDefault();
    };
  });
}
