import $ from "jquery";

class LoadMore {
  constructor() {
    this.initLoadMore();
  }

  /**
   * Load more posts when button loadmore is clicked
   */
  initLoadMore() {
    $(".button--loadmore").on("click", function (e) {
      var me = $(this);

      var post_type = $(me).attr("data-post-type");

      if (post_type == 'nyheter') {
        var post_list = me.prev(".news-list__wrap");
      }

      if (post_type == 'product') {
        var post_list = me.prev(".product-list__row");
      }

      var data = {
        action: "load_more",
        term: $(me).attr("data-term"),
        tax: $(me).attr("data-tax"),
        post_type: post_type,
        no_posts: parseInt($(me).attr("data-posts")),
        no_posts_loaded: parseInt($(me).attr("data-postsloaded")),
      };

      me.attr("disabled", true);
      me.addClass("button--loading");

      $.ajax({
        method: "post",
        url: ajaxurl,
        data: data,
        success: function (response) {
          if (response) {
            // add response HTML to markup
            $(post_list).append(response.html);

            // update loadmore button data-attr for post offset in next load
            var new_offset = data.no_posts + data.no_posts_loaded;
            $(me).attr("data-postsloaded", new_offset);

            // Hide load more button if no more posts
            if (response.show_load_more == false) {
              $(me).hide();
            } else {
              $(me).show();
            }
          }
          me.attr("disabled", false);
          me.removeClass("button--loading");
        },
        error: function (error) {
          me.attr("disabled", false);
          me.removeClass("button--loading");
        },
      });
    });
  }
}

new LoadMore();
