import $ from 'jquery';

$(function() {
  $(document).on('click', '.js-accordion .js-accordion-header', function (e)  {

    var dropDown = $(this).closest('.accordion__item').find('.accordion__body');
    $(this).closest('.js-accordion').find('.accordion__body').not(dropDown).slideUp();

    if ($(this).closest('.accordion__item').hasClass('active')) {
      $(this).closest('.accordion__item').removeClass('active');
    } else {
      $(this).closest('.js-accordion').find('.accordion__item.active').removeClass('active');
      $(this).closest('.accordion__item').addClass('active');
    }

    dropDown.stop(false, true).slideToggle();
    e.preventDefault();
  });
});
