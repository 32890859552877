import $ from 'jquery';

// fiter dropdown trigger
$('.filter').on('click', '.filter__opener, .filter-dropdown__link', function () {
  $(this).toggleClass('active');
  $('.filter-dropdown').slideToggle('fast', function () {
  });
});

$('.filter').on('click', '.filter-dropdown__item', function () {
  $(this).addClass('active');
  $(this).siblings().removeClass('active');
});

/**
 * Ajax filtering on posts.
 * Load posts with selected filter on click.
 */
$(document).on("click", ".filter-dropdown li a", function (e) {
  e.preventDefault();

  /**
   * First make sure we have set the nonce
   *
   * @var {string}
   */
  var nonce_value = $(this)
    .parent()
    .parent()
    .find(".js-nonce")
    .val();

  /**
   * Action name
   *
   * @var {string}
   */
  var action = $(".js-nonce").attr('data-action');

  /**
   * If nonce is empty stop script
   */
  if (!nonce_value) {
    return;
  }

  /**
   * Term name
   *
   * @var {string}
   */
  var term_name = $(this).attr("data-name");

  // Add css class loading
  var me = $('.filter__container');
  me.addClass("loading");

  // update term on loadmore button
  if ($(".button--loadmore").length) {
    $(".button--loadmore").attr("data-term", $(this).attr("data-slug"));
    var posts = $(".button--loadmore").attr("data-posts");
    $(".button--loadmore").attr("data-postsloaded", posts);
  }

  $.ajax({
    type: "POST",
    url: ajaxurl,
    data: {
      action: action,
      term: $(this).attr("data-slug"),
      brand: $(this).attr("data-brand-id"),
      nonce: $(this)
        .closest(".filter-dropdown")
        .find("input[name=_wpnonce]")
        .val(),
      no_posts: $('.button--loadmore').attr("data-posts"),
    },
    success: function (response) {
      /* Put response in this element */
      me.removeClass("loading");

      if (action && action == 'news_filter_terms') {
        $(".news-list__wrap").html(response.html);

        $(".filter__category-name").html(term_name);

        // Hide load more button if no more posts
        if (response.show_load_more == false) {
          $('.button--loadmore').hide();
        } else {
          $('.button--loadmore').show();
        }
      }

      if (action && action == 'brand_filter') {
        $("#brand-short-text").hide();
        $("#brand-content").html(response.html);
        $('#tabs-nav li:first-child').addClass('active');
        $('.tab-content').hide();
        $('.tab-content:first').show();

        if (response && response.show_short_text) {
          $("#brand-short-text").show();
        }
      }

      if (action && action == 'products_filter_terms') {
        $(".product-list__row").html(response.html);

        $(".filter__category-name").html(term_name);

        // Hide load more button if no more posts
        if (response.show_load_more == false) {
          $('.button--loadmore').hide();
        } else {
          $('.button--loadmore').show();
        }
      }

      return false;
    },
    error: function (error) {
      me.removeClass("loading");
    }
  });
});
