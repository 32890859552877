import $ from 'jquery';

jQuery(function() {
  initFoldoutAligner();
  initFoldout();
});

jQuery(window).resize(function() {
  initFoldoutAligner();
});

function initFoldoutAligner() {
  var foldoutBlock = jQuery('.product-list__col');
  jQuery.each(foldoutBlock, function() {
    var wrapper = jQuery(this).parents('.product-list');
    var offset = jQuery(this).offset();
    var parentOffset = wrapper.offset();
    var marginLeft =  parentOffset.left - offset.left;
    jQuery(this).find('.product-list__fold-out').css({'marginLeft': marginLeft, 'width': wrapper.width()});
  });
}

function initFoldout() {
  var hideclass = '.product-list__fold-out';
  jQuery(hideclass).hide();

  jQuery(document).on('click', '.fold-out-opener', function(event) {
    event.preventDefault();
    initFoldoutAligner();
    var parent = jQuery(this).parents('.product-list .product-list__col');
    parent.siblings('.product-list__col').find(hideclass).slideUp();
    parent.find(hideclass).slideDown();
    parent.addClass('fold-out-active');
    parent.siblings('.product-list__col').removeClass('fold-out-active');
  });

  jQuery(document).on('click', '.fold-out-closer', function(event) {
    event.preventDefault();
    jQuery(hideclass).slideUp();
    var parent = jQuery(this).parents('.product-list .product-list__col');
    parent.removeClass('fold-out-active');
  });
}
