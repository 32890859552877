import $ from 'jquery';

// Language menu trigger
$(function() {
  $(".language-opener").on("click", function(e) {
    $(".toolbar__item-wrap").toggleClass("active");
    e.stopPropagation()
  });
  $(document).on("click", function(e) {
    if ($(e.target).is(".toolbar__item-wrap") === false) {
      $(".toolbar__item-wrap").removeClass("active");
    }
  });
});
