import $ from "jquery";

// buger menu trigger
$(".nav-opener").on("click", function (e) {
  e.preventDefault();
  $("body").toggleClass("nav-active");
});

// dropdown menu
$(".nav").on("click", ".nav-dropdown__opener", function () {
  if ($(window).width() < 1024 || $("html").hasClass("touch")) {
    // console.log($(window).width() <= 1024 || $('html').hasClass('touch'));
    if ($(this).next("ul").is(":visible")) {
      $(this).next("ul").slideUp("fast");
      $(this).removeClass("active");
    } else {
      $(this)
        .closest("ul")
        .children(".menu-item-has-children")
        .children(".active")
        .next("ul")
        .slideUp("fast");
      $(this)
        .closest("ul")
        .children(".menu-item-has-children")
        .children(".active")
        .removeClass("active");
      $(this).next().slideToggle("fast");
      $(this).addClass("active");
    }
  }
});
