// if you're using a bundler, first import:
import Headroom from "headroom.js";
// grab an element
var myElement = document.getElementById("js-headeroom");
// construct an instance of Headroom, passing the element


var options = {
  // vertical offset in px before element is first unpinned
  offset : 20,
  // scroll tolerance in px before state changes
  tolerance : 0,
  // or you can specify tolerance individually for up/down scroll
  tolerance : {
      up : 5,
      down : 0
  }
};
// pass options as the second argument to the constructor
// supplied options are merged with defaults

var headroom  = new Headroom(myElement, options);
// initialise
headroom.init();